import { type JSX } from 'react';
import { Card, CardProps, List, formatCurrencyWithKr } from '@lanekassen/ui';

export interface AvtaleProps {
  navn: string;
  larested: string;
  opprettet: string;
  belop: number;
  statusText: string;
  epiTekster: {
    tittelAvtale: string;
    larested: string;
    datoOpprettet: string;
    belop: string;
    status: string;
  };
  children: JSX.Element;
  color: CardProps['color'];
}

export default function Avtale({
  navn,
  larested,
  opprettet,
  belop,
  statusText,
  epiTekster,
  children,
  color,
}: AvtaleProps) {
  return (
    <Card color={color} sx={{ marginTop: 3 }}>
      <Card.Header>
        {epiTekster.tittelAvtale} {navn}
      </Card.Header>
      <Card.Content>
        <List
          variant="definition"
          sx={{
            '& dt': {
              float: 'left',
              clear: 'left',
              width: 'auto',
              paddingRight: 1,
              fontWeight: 'bold',
            },
            '& dt:after': {
              content: '":"',
            },
          }}
        >
          <>
            <dt>{epiTekster.larested}</dt>
            <dd>{larested}</dd>
          </>
          <>
            <dt>{epiTekster.datoOpprettet}</dt>
            <dd>{opprettet}</dd>
          </>
          <>
            <dt>{epiTekster.belop}</dt>
            <dd>{formatCurrencyWithKr(belop)}</dd>
          </>
          <>
            <dt>{epiTekster.status}</dt>
            <dd>{statusText}</dd>
          </>
        </List>
      </Card.Content>
      <Card.Footer>{children}</Card.Footer>
    </Card>
  );
}
