import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCurrentLanguage } from './currentLangauge';

export type UserType = {
  isAuthenticated: boolean;
  fornavn: string | null;
  etternavn: string | null;
  kortnavn: string | null;
  impersonertAv: string | null;
};

const REQUEST_TIMEOUT_30_SECS = 30000;

async function handleIssues(res: Response) {
  const params = new URLSearchParams({
    ReturnUrl: window.location.pathname,
  });
  if (!res.ok) {
    switch (res.status) {
      case 401:
        window.location.href = `/du/logger_inn?${params}`;
        // Kaster exception slik at man stopper Promise-kjeden
        throw new Error('Unauthorized');
      case 403: {
        const operationId = res.headers.get('traceparent')?.split('-')[1];
        if (operationId) {
          params.append('operationId', operationId);
        }
        window.location.href = `/du/har_ikke_tilgang?${params}`;
        // Kaster exception slik at man stopper Promise-kjeden
        throw new Error('Forbidden');
      }
      default: {
        const text = await res.text();
        throw new Error(text);
      }
    }
  }
}

export const useDUQuery = () =>
  useQuery<UserType>({
    queryKey: ['du'],
    queryFn: async () => {
      const res = await fetch('/du/', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      await handleIssues(res);
      return res.json();
    },
  });

export function useTeksterQuery<T>(sideEllerBlokkNavn: string) {
  const queryClient = useQueryClient();
  const { localeCode } = useCurrentLanguage();
  return useQuery<T>({
    queryKey: ['tekster', localeCode, sideEllerBlokkNavn],
    queryFn: async ({ queryKey, signal }) => {
      setTimeout(
        () => queryClient.cancelQueries({ queryKey: ['tekster', localeCode, sideEllerBlokkNavn] }),
        REQUEST_TIMEOUT_30_SECS,
      );
      const res = await fetch(`/api/mt1534/CMSProxy/Foreldresignering/${queryKey[2]}?culture=${queryKey[1]}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });
      await handleIssues(res);
      return res.json();
    },
  });
}

export function useHentAvtalerQuery() {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['avtaler'],
    queryFn: async ({ signal }) => {
      setTimeout(() => queryClient.cancelQueries({ queryKey: ['avtaler'] }), REQUEST_TIMEOUT_30_SECS);
      const res = await fetch('/api/mt1534/Foreldresignering/HentAvtaler', {
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });
      await handleIssues(res);
      return res.json();
    },
  });
}

type OpprettSigneringsOppdragParams = { sakIdKryptert: string; lang: string };

export function useOpprettSigneringsOppdragMutation() {
  return useMutation({
    mutationKey: ['signeringsoppdrag'],
    mutationFn: async ({ sakIdKryptert, lang }: OpprettSigneringsOppdragParams) => {
      const abortController = new AbortController();
      setTimeout(() => abortController.abort(), REQUEST_TIMEOUT_30_SECS);
      const res = await fetch(`/api/mt1534/Foreldresignering/Oppdrag?culture=${lang}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
        body: JSON.stringify(sakIdKryptert),
        signal: abortController.signal,
      });
      await handleIssues(res);
      return res.text();
    },
  });
}
