import { Alert, Link } from '@lanekassen/ui';
import { HtmlString } from '@modulis/utils';
import { useEffect, useMemo, type JSX } from 'react';
import { useForsideteksterContext } from '../context/forsideteksterContext';

export const SigneringStatus = {
  MISLYKKET: 'SigneringMislyktes',
  AVBRUTT: 'SigneringAvbrutt',
  FULLFORT: 'SigneringFullfort',
} as const;

type SigneringStatusType = (typeof SigneringStatus)[keyof typeof SigneringStatus];

interface Signeringsmelding {
  tittel: string;
  tekst: string;
  severity: 'error' | 'warning' | 'info';
}

export interface SigneringsStatusMeldingProps {
  refreshInnhold: () => void;
}

function SigneringsStatusMelding({ refreshInnhold }: SigneringsStatusMeldingProps): JSX.Element | undefined {
  const forsideTekster = useForsideteksterContext();
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const signeringsstatus = queryParams.get('status') as SigneringStatusType | null;

  // Fjern queryParams fra URL'en
  useEffect(() => {
    window.history.replaceState(undefined, '', window.location.pathname);
    // Data lengere ned på siden kan være utdattert
    // 360 kan være litt treg noen ganger
    // Så vi tvinger en refresh
    if (signeringsstatus === SigneringStatus.FULLFORT) setTimeout(refreshInnhold, 3000);
  }, []);

  let LastNedSeksjon: JSX.Element | null = null;

  if (!signeringsstatus) {
    return undefined;
  }
  let signeringsmelding: Signeringsmelding = {
    tittel: '',
    tekst: '',
    severity: 'error',
  };

  if (signeringsstatus === SigneringStatus.AVBRUTT) {
    signeringsmelding = {
      severity: 'warning',
      tittel: forsideTekster.avbruttSigneringOverskrift,
      tekst: forsideTekster.avbruttSigneringUndertittel,
    };
  } else if (signeringsstatus === SigneringStatus.FULLFORT) {
    const signeringsoppdragIdKryptert = queryParams.get('signeringsoppdragIdKryptert');
    const eksterntArkiv = queryParams.get('eksterntArkiv');

    LastNedSeksjon = (
      <Link
        isExternal
        href={`${window.location.origin}/api/mt1534/Signering/HentSignertDokument?arkivreferanseKryptert=${signeringsoppdragIdKryptert}&eksterntArkiv=${eksterntArkiv}`}
      >
        {forsideTekster.bekreftelseLastNedSignertDokumentLenkeTekst}
      </Link>
    );

    // TODO: Denne har ingen melding i gamle?
    signeringsmelding = {
      severity: 'info',
      tittel: forsideTekster.vellykketSigneringOverskrift,
      tekst: forsideTekster.vellykketSigneringUndertittel,
    };
  } else if (signeringsstatus === SigneringStatus.MISLYKKET) {
    signeringsmelding = {
      severity: 'error',
      tittel: forsideTekster.feiletSigneringOverskrift,
      tekst: forsideTekster.feiletSigneringTekst.value,
    };
  }

  return (
    <Alert severity={signeringsmelding.severity}>
      <Alert.Header>{signeringsmelding.tittel}</Alert.Header>
      <HtmlString value={{ value: signeringsmelding.tekst }} />
      {LastNedSeksjon}
    </Alert>
  );
}

export default SigneringsStatusMelding;
