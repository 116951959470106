import { type JSX } from 'react';
import { Divider, Heading, Skeleton, Stack } from '@lanekassen/ui';
import { TrekkspillBlock } from '../epitekster/types';
import { useTeksterQuery } from '../hooks/queries';

interface TrekkspillElementWithLoaderProps {
  contentGuid: string;
  children: (trekkspillElementIds: string[]) => JSX.Element;
}

function TrekkspillWithLoader({ contentGuid, children }: TrekkspillElementWithLoaderProps) {
  const { data: trekkspillBlock, isLoading } = useTeksterQuery<TrekkspillBlock>(`TrekkspillBlock/${contentGuid}`);

  if (isLoading) {
    return <Skeleton variant="rectangular" height={40} />;
  }
  return (
    <Stack spacing={2}>
      <Heading level={2}>{trekkspillBlock?.overskrift}</Heading>
      <Divider />
      {!!trekkspillBlock && children(trekkspillBlock.trekkspillelementer.map((i) => i.contentGuid))}
    </Stack>
  );
}

export default TrekkspillWithLoader;
