import { Alert, Divider, Heading } from '@lanekassen/ui';
import { HtmlString } from '@modulis/utils';
import { ReactNode } from 'react';

interface AvtaleSeksjonProps {
  tittel: string;
  feilmeldingstekst: string;
  fikkTekniskFeil: boolean;
  children: ReactNode;
}
function AvtaleSeksjon({ tittel, feilmeldingstekst, fikkTekniskFeil, children }: AvtaleSeksjonProps) {
  return (
    <>
      <Heading level={2} marginTop={3} marginBottom={1}>
        {tittel}
      </Heading>
      <Divider />
      {fikkTekniskFeil && (
        <Alert severity="error">
          <HtmlString value={{ value: feilmeldingstekst }} />
        </Alert>
      )}
      {!fikkTekniskFeil && children}
      <Divider />
    </>
  );
}

export default AvtaleSeksjon;
